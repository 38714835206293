import axios from '@/axios';

class UserService {
    index(uriParams) {
        const reducer = (accumulator, currentValue) => accumulator += currentValue.join('=') + '&';

        let url = '/settings/users' + Object.entries(uriParams).reduce(reducer, '?').slice(0, -1)

        return axios.get(url);
    }
    add(data) {
        return axios.post('/settings/users', data);
    }
    showUser(id) {
        return axios.get(`/settings/users/${id}`);
    }
    update(id, data) {
        return axios.put(`/settings/users/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/settings/users/${id}`);
    }
    invite(data) {
        return axios.post('/settings/users/invite', data);
    }
}

export const userService = new UserService();