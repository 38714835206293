<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Create User') }}</h3>
                    <p>{{ groupName }}</p>
                </div>
                
                <FormErrors v-if="errors[`group_name`]" :errors="errors[`group_name`]" />

                <form @submit.prevent="addUser" class="invite-form">
                    <div class="form-group">
                        <input v-model="user.profile.first_name" type="text" :class="['form-control', {'has-error': errors['profile.first_name']}]" :placeholder="$t('First name')">
                        
                        <FormErrors v-if="errors[`profile.first_name`]" :errors="errors[`profile.first_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.profile.last_name" type="text" :class="['form-control', {'has-error': errors['profile.last_name']}]" :placeholder="$t('Last name')">
                        
                        <FormErrors v-if="errors[`profile.last_name`]" :errors="errors[`profile.last_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.email" type="email" :class="['form-control', {'has-error': errors['email']}]" :placeholder="$t('Email')">

                        <FormErrors v-if="errors[`email`]" :errors="errors[`email`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.profile.phone_number" type="text" :class="['form-control', {'has-error': errors['profile.phone_number']}]" :placeholder="$t('Phone')">
                        
                        <FormErrors v-if="errors[`profile.phone_number`]" :errors="errors[`profile.phone_number`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.password" type="password" :class="['form-control', {'has-error': errors['password']}]" :placeholder="$t('Password')">
                        
                        <FormErrors v-if="errors[`password`]" :errors="errors[`password`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.password_confirmation" type="password" :class="['form-control', {'has-error': errors['password_confirmation']}]" :placeholder="$t('Repeat password')">

                        <FormErrors v-if="errors[`password_confirmation`]" :errors="errors[`password_confirmation`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.language_id"
                            :options="languages"
                            :placeholder="$t('Select language')"
                            :class="['form-control', {'has-error': errors['profile.language_id']}]"
                            :searchable="true"
                        />
                        <FormErrors v-if="errors[`profile.language_id`]" :errors="errors[`profile.language_id`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.country_id"
                            :options="countries"
                            :placeholder="$t('Select country')"
                            :class="['form-control', {'has-error': errors['profile.country_id']}]"
                            :searchable="true"
                        />

                        <FormErrors v-if="errors[`profile.country_id`]" :errors="errors[`profile.country_id`]" />
                    </div>

                    <button class="btn btn-primary">{{ $t('Add') }}</button>
                </form>

            </div>
        </template>
    </Modal>
</template>

<script>
import {userService} from '@/services/settings/userService'
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import Multiselect from '@vueform/multiselect'

export default {
    name: "AddUser",
    components: {
        Modal,
        FormErrors,
        Multiselect
    },
    data() {
        return {
            user: {
                profile: {},
            },


        }
    },
    computed: {
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    props: ['groupName'],
    methods: {
        addUser() {
            this.user.user_group_id = this.$store.getters.getUserGroupId(this.groupName)
            userService.add(this.user).then(response => {
                this.$store.commit('addUser', response.data.data)
                this.$emit('closeModal')
            }).catch(error => {
                this.errors = error.response.data.errors || []
            })
        }
    }
}
</script>